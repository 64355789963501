import Api from "@/service/api";

export default class VariableService {
  update(variable) {
    return Api()
      .put("/variables/" + variable.variableId, variable)
      .then((res) => res.data);
  }

  variable(name) {
    return Api(false)
      .get("/variables/name/" + name)
      .then((res) => res.data);
  }
}
