<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />
        <template v-if="!loading">
          <ck-editor v-model="partners.valueString" />
          <div class="row">
            <div class="col-12">
              <Button
                label="Modifier"
                class="button-custom button-info p-2"
                @click="updatePartners()"
              />
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import CkEditor from "@components/CkEditor.vue";
import VariableService from "@services/VariableService";

export default {
  name: "PartnersBack",

  components: { CkEditor },

  mixins: [],

  data() {
    return {
      loading: false,
      partners: {},
    };
  },

  variableService: null,

  created() {
    this.variableService = new VariableService();
  },

  mounted() {
    this.variableService.variable("partners").then((data) => {
      this.partners = data;
      this.loading = false;
    });
  },

  methods: {
    updatePartners() {
      this.variableService.update(this.partners).then(() => {
        this.$toast.add({
          severity: "success",
          summary: "Terminé",
          detail: "La page de partenaires a bien été modifiée",
          life: 3000,
        });
      });
    },
  },
};
</script>

<style lang="scss">
.ck-image-insert-form {
  .ck-file-dialog-button {
    display: none !important;
  }
}
</style>
